@font-face {
    font-family: 'Planet-Joy';
    src:  url('planet-joy.eot');
    src:  url('planet-joy.eot') format('embedded-opentype'),
      url('planet-joy.ttf') format('truetype'),
      url('planet-joy.woff') format('woff'),
      url('./petlandtic-logo-svg.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
/* Webfont: Sinteca-Regular */@font-face {
    font-family: 'Sinteca';
    src: url('Sinteca-Regular.eot'); /* IE9 Compat Modes */
    src: url('Sinteca-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('Sinteca-Regular.woff') format('woff'), /* Modern Browsers */
    url('Sinteca-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('Sinteca-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Planet-Joy' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e903";
}
.icon-plus:before {
  content: "\e900";
}
.icon-minus:before {
  content: "\e901";
}
.icon-Icon:before {
  content: "\e902";
}
